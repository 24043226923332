import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import Container from '../../../components/Container'
import Heading from '../../../components/Heading'
import { LoadingIndicator } from '../../../components/LoadingIndicator/LoadingIndicator.component'
import Text from '../../../components/Text'
import PortalRouteWrapper from '../../../containers/PortalRouteWrapper'
import useL3Access from '../../../hooks/useL3Access'
import { RootState } from '../../../redux/store'
import { IUserInformation } from '../../../shared/interfaces'
import ChangeBasicInformationForm from './BasicInformation/ChangeBasicInformationForm'
import { ChangeEmailForm } from './ChangeEmailForm/ChangeEmailForm.component'
import ChangePasswordForm from './Password/ChangePasswordForm'

const mapStateToProps = (state: RootState) => {
  return {
    userInfo: state.me.userInfo,
  }
}

const UserProfile: FunctionComponent<{ userInfo?: IUserInformation }> = ({ userInfo }) => {
  const { t: translate } = useTranslation()
  const hasL3Access = useL3Access()

  return (
    <PortalRouteWrapper title={translate('user-profile.title')}>
      <Heading level="h1" visualLevel="h4" color="bf-blue">
        {translate('user-profile.title')}
      </Heading>

      {!hasL3Access ? <Text>{translate('user-profile.modify-l3access')}</Text> : null}

      <Container gap="extra-large" padding="none" color="bf-blue">
        {!userInfo && <LoadingIndicator />}
        {userInfo ? (
          <>
            <ChangeBasicInformationForm data={userInfo} />
            <ChangeEmailForm data={userInfo} />
            <ChangePasswordForm data={userInfo} />
          </>
        ) : null}
      </Container>
    </PortalRouteWrapper>
  )
}

// eslint-disable-next-line react-redux/prefer-separate-component-file
export default connect(mapStateToProps)(UserProfile)
